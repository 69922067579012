body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.center {
  margin: auto;
  width: 80%;
  padding: 50px;
}

html,
body {
  background-image: none !important;
  background-color: rgba(40, 42, 54, 1) !important;
}

body * {
  color: rgba(248, 248, 242, 1) !important;
  background-color: rgba(40, 42, 54, 1) !important;
}

a,
a * {
  color: rgba(80, 250, 123, 1) !important;
  background-color: transparent !important;
}

a:hover,
a:hover *,
a:visited:hover,
a:visited:hover *,
span[onclick]:hover,
div[onclick]:hover,
[role="link"]:hover,
[role="link"]:hover *,
[role="button"]:hover *,
[role="menuitem"]:hover,
[role="menuitem"]:hover *,
.link:hover,
.link:hover * {
  color: white !important;
}

a:visited,
a:visited * {
  color: rgba(98, 114, 164, 1) !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
[id*="headline"],
[class*="headline"],
[id*="header"],
[class*="header"],
[class*="header"] td {
  color: rgba(255, 184, 108, 1) !important;
}

table {
  background-color: rgba(40, 42, 54, 1) !important;
}

[id*="overlay"],
[id*="lightbox"],
blockquote {
  background-color: rgba(68, 71, 90, 1) !important;
}

pre,
dl {
  background-color: rgba(68, 71, 90, 1) !important;
}

/* input, */
select,
button,
[role="button"],
a.button,
a.submit,
a.BigButton,
a.TabLink,
.install[onclick] {
  text-indent: 5px;
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  background: rgba(68, 71, 90, 1) !important;
}

textarea {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  background: rgba(68, 71, 90, 1) !important;
}

div,
ul,
li {
  background-image: none !important;
}

a.highlight,
a.highlight *,
a.active,
a.active *,
.selected,
.selected *,
[href="#"] {
  font-weight: bold !important;
  color: rgba(139, 233, 253, 1) !important;
}

img {
  border: 0;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.transition-opacity {
  transition: opacity 0.3s ease;
}

.overlay {
  background: rgba(0, 0, 0, 0.5) !important;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.group:hover .overlay {
  opacity: 1 !important;
}

.text-white {
  color: #ffffff !important;
}

.rounded-tl-none {
  border-top-left-radius: 0 !important;
}

.rounded-tr-none {
  border-top-right-radius: 0 !important;
}

.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-br-lg {
  border-bottom-right-radius: 0.5rem !important;
}

.App {
  display: flex;
}

.sidebar {
  width: 200px;
  padding: 20px;
}

@media only screen and (max-width: 600px) {
  .sidebar {
    width: 0%;
    padding: 10px;
  }
}

/* Стили для экранов шириной до 480px */
@media only screen and (max-width: 480px) {
  .sidebar {
    width: 0%;
    padding: 5px;
  }
}

.content {
  flex: 1;
  padding: 20px;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  /* Закругляем углы */
  border: 1px solid #ccc;
  /* Добавляем рамку */
  margin-right: 5px;
  /* Отступ справа */
  vertical-align: middle;
  /* Выравнивание по вертикали */
  outline: none;
  /* Убираем контур вокруг чекбокса */
}

input[type="checkbox"]:checked {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  /* Закругляем углы */
  border: 1px solid #ccc;
  /* Добавляем рамку */
  margin-right: 5px;
  /* Отступ справа */
  vertical-align: middle;
  /* Выравнивание по вертикали */
  outline: none;
  /* Убираем контур вокруг чекбокса */
}

/* Скрытие оригинального чекбокса */
input[type="checkbox"]::-webkit-inner-spin-button,
input[type="checkbox"]::-webkit-outer-spin-button,
input[type="checkbox"]::-webkit-input-placeholder {
  display: none;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  cursor: pointer;
  margin-bottom: 5px;
  /* Уменьшим отступ между пунктами */
  display: flex;
  /* Разместим содержимое пункта в ряд */
  align-items: center;
  /* Выровняем по вертикали */
}

.sidebar input[type="checkbox"] {
  margin-right: 5px;
  /* Отступ между чекбоксом и текстом */
  vertical-align: middle;
  /* Выравниваем по вертикали */
}
